import React from 'react';
import { Router } from '@reach/router';
import { Redemption } from '../components/routes';

const RedemptionPage = () => {
  return (
    <Router basepath="redemption">
      <Redemption path=":id" />
      <Redemption path=":id/:amount" />
    </Router>
  );
};

export default RedemptionPage;
